/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LanguageTexts from '../../../../common/language';
import { OpenPaymentOrderInput, RootState } from '../../../../common/types';
import {
  generateWithdrawUrl,
  getWithdrawalRequests,
} from '../../profile.slice';
import SidePanelPage from '../../side-panel/SidePanelPage';
import WithdrawMoneyForm from './WithdrawMoneyForm';

const WithdrawMoneyPage: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const { account: accountTxt, app: appTxt } = LanguageTexts;
  const {
    withdrawUrl,
    withdrawalRequests,
    generateWithdrawUrl: {
      success: generateWithdrawUrlSuccess,
      loading: generateWithdrawUrlLoading,
      errors: generateWithdrawUrlErrors,
    },
    withdrawalRequestState: {
      success: withdrawalRequestSuccess,
      loading: withdrawalRequestLoading,
      errors: withdrawalRequestErrors,
    },
  } = useSelector((state: RootState) => state.profile);
  const { user } = useSelector((state: RootState) => state.login);

  useEffect(() => {
    dispatch(getWithdrawalRequests());
  }, [dispatch]);

  useEffect(() => {
    if (generateWithdrawUrlSuccess) {
      if (withdrawUrl) {
        window.location.href = withdrawUrl.url;
      }
    }
  }, [generateWithdrawUrlSuccess, withdrawUrl]);

  function onSubmit(input: OpenPaymentOrderInput) {
    dispatch(generateWithdrawUrl(input));
  }

  console.log(withdrawalRequests);

  return (
    <>
      <div id="main-wrapper" className="bg-primary gradient-color userpage">
        <div id="content" className="py-4 main-user-section">
          <img
            src={`${process.env.REACT_APP_IMAGE_PATH}website/shape01-bg.png`}
            alt=""
            className="shape-1"
          />
          <img
            src={`${process.env.REACT_APP_IMAGE_PATH}website/shape02-bg.png`}
            alt=""
            className="shape-2"
          />
          <div className="container">
            <div className="row">
              <aside className="col-lg-3">
                <SidePanelPage />
              </aside>
              <div className="col-lg-9">
                <div className="card-box bg-light shadow-sm rounded p-4 mb-4">
                  <div className="text-center bg-primary py-5 rounded mb-4">
                    <h3 className="text-12 text-white font-weight-400">
                      {appTxt.currencySymbol}
                      {user ? Number(user.wallet.toFixed(0)).toLocaleString().split(/\s/).join(',') : 0}
                    </h3>
                    <p className="text-white mb-0">
                      {accountTxt.availableBalanceText}
                    </p>
                  </div>
                  <WithdrawMoneyForm
                    onSubmit={onSubmit}
                    errors={generateWithdrawUrlErrors}
                    loading={generateWithdrawUrlLoading}
                  />
                </div>
                <div className="card-box bg-light shadow-sm rounded p-4 mb-4">
                  <h3 className="text-8 font-weight-400 mb-4">
                    {accountTxt.withdrawalRequestHeading1}
                  </h3>
                  <h3 className="text-5 font-weight-500 text-primary mb-4">
                    {accountTxt.withdrawalRequestHeading2}
                  </h3>
                  <table className="table table-bordered withdrawal-request-table">
                    <thead>
                      <tr>
                        <th className="text-muted">
                          {accountTxt.withdrawalRequestTableHeading1}
                        </th>
                        <th className="text-muted">
                          {accountTxt.withdrawalRequestTableHeading2}
                        </th>
                        <th className="text-muted">
                          {accountTxt.withdrawalRequestTableHeading3}
                        </th>
                        <th className="text-muted">
                          {accountTxt.withdrawalRequestTableHeading4}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {withdrawalRequests && withdrawalRequests.length > 0 ? (
                        withdrawalRequests.map((withdrawalRequest, index) => {
                          return (
                            <tr key={`KEY${index}`}>
                              <td>{withdrawalRequest.wdRequestId}</td>
                              <td>{withdrawalRequest.requestedAmount}</td>
                              <td>{withdrawalRequest.requestedCurrency}</td>
                              <td>{withdrawalRequest.wdRequestStatus}</td>
                            </tr>
                          );
                        })
                      ) : withdrawalRequests !== null ? (
                        <tr>
                          <td className="text-6 text-center font-weight-500 py-5" colSpan={4}>
                            {accountTxt.noWithdrawalRequestText}
                          </td>
                        </tr>
                      ) :
                          (
                            <tr>
                              <td className="text-6 text-center font-weight-500 py-5" colSpan={4}>
                                {appTxt.loading}
                              </td>
                            </tr>
                          )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WithdrawMoneyPage;
