/* eslint-disable no-param-reassign */
import client from '../app/apolo.client';
import { TOKEN_KEY } from '../common/constants';
import {
  ADD_CONTACT_US,
  ADD_MONEY_TO_WALLET,
  ALL_USER_ONGOING_POOL,
  CHANGE_PASSWORD,
  CHECK_USER_TRANSACTION,
  DEDUCT_MONEY_FROM_WALLET,
  DELETE_CARD,
  FETCH_USER_BET,
  FETCH_USER_BETS,
  FORGOT_PASSWORD,
  GENERATE_WITHDRAW_URL,
  GET_ADDRESS,
  GET_COUNTRIES,
  GET_GAMES,
  GET_POOLS,
  GET_SPORT_TYPES,
  GET_STATES,
  GET_USER_COUNT_IN_POOL,
  GET_WITHDRAWAL_REQUESTS,
  JOIN_POOL,
  LOAD_USER_CARD_INFO,
  LOGIN,
  MY_ONGOING_POOL,
  MY_POOL_BETS,
  OPEN_PAYMENT_ORDER,
  SAVE_BET,
  SET_DEFAULT_CARD,
  SIGNUP,
  SOCIAL_LOGIN,
  TRANSACTION_CALLBACK,
  UPDATE_PROFILE,
  UPLOAD_PROFILE_PHOTO,
  WHOAMI,
} from '../common/gql';
import {
  extractErrors,
  formatApiException,
  formatApiResponse,
} from '../common/helper';
import {
  AddCardInput,
  AddMoneyToWalletInput,
  AddressModel,
  BetInput,
  CardModel,
  ChangeDefaultCardInput,
  ChangePasswordInput,
  ContactUsInput,
  CountryModel,
  DeductMoneyFromWalletInput,
  ForgotPasswordInput,
  GameModel,
  JoinPoolInput,
  LoginInput,
  OpenOrderModel,
  OpenPaymentOrderInput,
  PoolModel,
  RegisterInput,
  SelectedBet,
  SocialLoginInput,
  SportTypeModel,
  StateModel,
  TransactionInput,
  TransactionModel,
  UpdateProfileInput,
  UpdateProfilePicInput,
  UserBetModel,
  UserModel,
  UserPoolOngoingModel,
  WithdrawalRequestModel,
  WithdrawURLModel,
} from '../common/types';

export default class ApiService {
  static async login(
    payload: LoginInput,
  ): Promise<{
    data: UserModel | null;
    errors: string[] | null;
  }> {
    try {
      delete payload.page;
      const { data, errors } = await client.mutate({
        mutation: LOGIN,
        variables: { input: payload },
      });

      return formatApiResponse('login', data, errors ? [...errors] : null);
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async socialLogin(
    payload: SocialLoginInput,
  ): Promise<{
    data: UserModel | null;
    errors: string[] | null;
  }> {
    try {
      const { id } = payload;
      delete payload.page;
      const { data, errors } = await client.mutate({
        mutation: SOCIAL_LOGIN,
        variables: { id },
      });

      return formatApiResponse(
        'socialLogin',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async register(
    payload: RegisterInput,
  ): Promise<{
    data: UserModel | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: SIGNUP,
        variables: { input: payload },
      });

      return formatApiResponse('register', data, errors ? [...errors] : null);
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async forgotPassword(
    payload: ForgotPasswordInput,
  ): Promise<{
    data: boolean | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: FORGOT_PASSWORD,
        variables: { input: payload },
      });

      return formatApiResponse(
        'forgotPassword',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async whoami(): Promise<{
    data: UserModel | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({ query: WHOAMI });
      return formatApiResponse('whoAmI', data, errors ? [...errors] : null);
    } catch (e) {
      if (e.message === 'Unauthorized') {
        localStorage.removeItem(TOKEN_KEY);
        window.location.replace('/');
      }

      return formatApiException(e);
    }
  }

  static async getAddress(): Promise<{
    data: AddressModel | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({ query: GET_ADDRESS });
      return formatApiResponse('getAddress', data, errors ? [...errors] : null);
    } catch (e) {
      if (e.message === 'Unauthorized') {
        localStorage.removeItem(TOKEN_KEY);
        window.location.replace('/');
      }

      return formatApiException(e);
    }
  }

  static async getCountries(): Promise<{
    data: CountryModel | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({ query: GET_COUNTRIES });
      return formatApiResponse('countries', data, errors ? [...errors] : null);
    } catch (e) {
      if (e.message === 'Unauthorized') {
        localStorage.removeItem(TOKEN_KEY);
        window.location.replace('/');
      }

      return formatApiException(e);
    }
  }

  static async getStates(
    countryId: string,
  ): Promise<{
    data: StateModel | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: GET_STATES,
        variables: { countryId },
      });
      return formatApiResponse('states', data, errors ? [...errors] : null);
    } catch (e) {
      if (e.message === 'Unauthorized') {
        localStorage.removeItem(TOKEN_KEY);
        window.location.replace('/');
      }

      return formatApiException(e);
    }
  }

  static async getGames(
    params: any,
  ): Promise<{
    data: GameModel | null;
    errors: string[] | null;
  }> {
    const { pool, sportType } = params;
    try {
      const { data, errors } = await client.query({
        query: GET_GAMES,
        variables: { pool, sportType },
      });
      return formatApiResponse('listGames', data, errors ? [...errors] : null);
    } catch (e) {
      if (e.message === 'Unauthorized') {
        localStorage.removeItem(TOKEN_KEY);
        window.location.replace('/');
      }

      return formatApiException(e);
    }
  }

  static async getPools(): Promise<{
    data: PoolModel | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: GET_POOLS,
      });
      return formatApiResponse('listPools', data, errors ? [...errors] : null);
    } catch (e) {
      if (e.message === 'Unauthorized') {
        localStorage.removeItem(TOKEN_KEY);
        window.location.replace('/');
      }

      return formatApiException(e);
    }
  }

  static async getSportTypes(): Promise<{
    data: SportTypeModel | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: GET_SPORT_TYPES,
      });
      return formatApiResponse(
        'listSportTypes',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      if (e.message === 'Unauthorized') {
        localStorage.removeItem(TOKEN_KEY);
        window.location.replace('/');
      }

      return formatApiException(e);
    }
  }

  static async saveBet(
    payload: BetInput,
  ): Promise<{
    data: { _id: string } | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: SAVE_BET,
        variables: { input: payload },
      });

      return formatApiResponse('addUserBet', data, errors ? [...errors] : null);
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async loadUserBet(
    id: string,
  ): Promise<{
    data: SelectedBet | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: FETCH_USER_BET,
        variables: { id },
      });

      return formatApiResponse(
        'fetchUserBet',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async checkTransaction(
    poolId: string,
  ): Promise<{
    data: boolean | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: CHECK_USER_TRANSACTION,
        variables: { input: { poolId } },
      });

      return formatApiResponse(
        'checkUserTransaction',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async myOngoingPool(
    poolId: string,
  ): Promise<{
    data: UserPoolOngoingModel | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: MY_ONGOING_POOL,
        variables: { input: { poolId } },
      });

      return formatApiResponse(
        'myOngoingPool',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async allUserOngoingPools(): Promise<{
    data: [UserPoolOngoingModel] | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: ALL_USER_ONGOING_POOL,
        variables: {},
      });

      return formatApiResponse(
        'allUserOngoingPool',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async myPoolBets(
    type: string,
  ): Promise<{
    data: [UserPoolOngoingModel] | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: MY_POOL_BETS,
        variables: { type },
      });
      return formatApiResponse('myPoolBets', data, errors ? [...errors] : null);
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async getUserCountInPool(
    poolId: string,
  ): Promise<{
    data: number | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: GET_USER_COUNT_IN_POOL,
        variables: { input: { poolId } },
      });

      return formatApiResponse(
        'getUserCountInPool',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async transactionCallback(
    payload: TransactionInput,
  ): Promise<{
    data: TransactionModel | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: TRANSACTION_CALLBACK,
        variables: { input: payload },
      });

      return formatApiResponse(
        'transactionCallback',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async joinPool(
    payload: JoinPoolInput,
  ): Promise<{
    data: UserPoolOngoingModel | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: JOIN_POOL,
        variables: { input: payload },
      });

      return formatApiResponse('joinPool', data, errors ? [...errors] : null);
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async addMoneyToWallet(
    payload: AddMoneyToWalletInput,
  ): Promise<{
    data: boolean | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: ADD_MONEY_TO_WALLET,
        variables: { input: payload },
      });

      return formatApiResponse(
        'addMoneyToWallet',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async deductMoneyFromWallet(
    payload: DeductMoneyFromWalletInput,
  ): Promise<{
    data: boolean | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: DEDUCT_MONEY_FROM_WALLET,
        variables: { input: payload },
      });

      return formatApiResponse(
        'deductMoneyFromWallet',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async fetchUserBets(
    params: any,
  ): Promise<{
    data: UserBetModel | null;
    errors: string[] | null;
  }> {
    const { type } = params;
    try {
      const { data, errors } = await client.query({
        query: FETCH_USER_BETS,
        variables: { type },
      });
      return formatApiResponse(
        'fetchUserBets',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      if (e.message === 'Unauthorized') {
        localStorage.removeItem(TOKEN_KEY);
        window.location.replace('/');
      }

      return formatApiException(e);
    }
  }

  static async updateCurrentUserProfilePic(
    payload: UpdateProfilePicInput,
  ): Promise<{
    data: UserModel | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: UPLOAD_PROFILE_PHOTO,
        variables: { input: payload },
      });

      return formatApiResponse(
        'updateCurrentUserProfilePic',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async updateProfile(
    payload: UpdateProfileInput,
  ): Promise<{
    data: UserModel | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: UPDATE_PROFILE,
        variables: { input: payload },
      });

      return formatApiResponse(
        'updateProfile',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async changePassword(
    payload: ChangePasswordInput,
  ): Promise<{
    data: boolean | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: CHANGE_PASSWORD,
        variables: { input: payload },
      });

      return formatApiResponse(
        'changePassword',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async loadCardInfo(): Promise<{
    data: CardModel | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: LOAD_USER_CARD_INFO,
      });

      const userCard: CardModel = data
        ? formatApiResponse('listCard', data, null).data
        : null;

      return {
        data: userCard,
        errors: errors ? extractErrors([...errors]) : null,
      };
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async setDefaultPaymentMethod(
    payload: ChangeDefaultCardInput,
  ): Promise<{
    data: boolean | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: SET_DEFAULT_CARD,
        variables: { input: payload },
      });

      return formatApiResponse(
        'changeDefaultCard',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async deletePaymentMethod(
    payload: ChangeDefaultCardInput,
  ): Promise<{
    data: boolean | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: DELETE_CARD,
        variables: { input: payload },
      });

      return formatApiResponse('deleteCard', data, errors ? [...errors] : null);
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async addContactUs(
    payload: ContactUsInput,
  ): Promise<{
    data: boolean | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: ADD_CONTACT_US,
        variables: { input: payload },
      });

      return formatApiResponse(
        'addContactUs',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async openPaymentOrder(
    payload: OpenPaymentOrderInput,
  ): Promise<{
    data: OpenOrderModel | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: OPEN_PAYMENT_ORDER,
        variables: { input: payload },
      });

      return formatApiResponse(
        'openPaymentOrder',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async generateWithdrawUrl(
    payload: OpenPaymentOrderInput,
  ): Promise<{
    data: WithdrawURLModel | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: GENERATE_WITHDRAW_URL,
        variables: { input: payload },
      });

      return formatApiResponse(
        'generateWithdrawUrl',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async getWithdrawalRequests(): Promise<{
    data: WithdrawalRequestModel | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: GET_WITHDRAWAL_REQUESTS,
      });

      const requests: WithdrawalRequestModel = data
        ? formatApiResponse('getWithdrawalRequests', data, null).data
        : null;

      return {
        data: requests,
        errors: errors ? extractErrors([...errors]) : null,
      };
    } catch (e) {
      return formatApiException(e);
    }
  }
}
