export type ILanguageTexts = {
  app: Record<string, string>;
  register: Record<string, string>;
  login: Record<string, string>;
  forgotPassword: Record<string, string>;
  auth: Record<string, string>;
  homePage: Record<string, string>;
  contactUs: Record<string, string>;
  games: Record<string, string>;
  profile: Record<string, string>;
  account: Record<string, string>;
  topHeader: Record<string, string>;
};

const LanguageTexts: ILanguageTexts = {
  app: {
    loading: 'Loading...',
    sending: 'Sending...',
    join: 'Join',
    each: 'Each',
    maxSize: 'Max. Size',
    resolution: 'Recommended file size - ',
    resolutionSize: '500 x 300',
    maxSizeErr: 'Max allowed image size is {size}',
    mandatoryNote: '* Fields are mandatory',
    invalidCard: 'Please provide valid card details.',
    invalidDate: '$property must be a valid date',
    confirmText: 'Are you sure to do this?',
    yes: 'Yes',
    no: 'No',
    chooseRole: 'Choose Role',
    chooseCenter: 'Choose Center',
    chooseBatch: 'Choose Batch',
    choose: 'Choose',
    readMore: 'Read More',
    btnSubmit: 'Submit',
    notFound: 'Not Found',
    noData: 'No Data Found',
    phoneNote: 'For Ex. 5417540000',
    InvalidValue: '$property contains invalid value',
    metaTitle: 'Institute management application | Impeccable Tracker',
    metaDescription:
      'Web and mobile application software for Institute management. Manage classes, schedules, attendance, payments easily though Impeccable Tracker.',
    metaKeywords:
      'Class management, Class schedule, class management, attendee tracking, assignment review, online fees collection, sports class management, yoga class management, Music class management',
    institute: 'Institute',
    of: 'of',
    classesFor: 'classes for kids and adults',
    specializedIn: 'specialized in',
    conductingClasses: 'Currently conducting classes in',
    currencySymbol: '$',
    noRecordsFound: 'No Records Found'
  },
  register: {
    firstNameLabel: 'First Name',
    lastNameLabel: 'Last Name',
    emailAddressLabel: 'E-Mail Address',
    passwordLabel: 'Password',
    mobileLabel: 'Mobile',
    addressLabel: 'Address',
    cityLabel: 'City',
    stateLabel: 'State',
    zipLabel: 'Zip Code',
    countryLabel: 'Country',
    agreeTermsText: 'I agree to the ',
    termsConditionLinkText: 'Terms & Condition',
    emailErrorText: 'Please enter your valid email address',
    emailInvalidErrorText: 'Your email address is invalid',
    passwordErrorText: 'Please enter your password',
    firstNameErrorText: 'Please enter your first name',
    firstNameInvalidErrorText: 'Your first name is invalid',
    lastNameErrorText: 'Please enter your last name',
    lastNameInvalidErrorText: 'Your last name is invalid',
    addressErrorText: 'Please enter your address',
    cityErrorText: 'Please enter your city',
    zipErrorText: 'Please enter your zip',
    stateErrorText: 'Please enter your state',
    countryErrorText: 'Please select your country',
    acceptTermsErrorText: 'Please accept terms & condition',
    registerText: 'Register',
    orText: 'OR',
    otherSignInText: 'Sign in using',
    alreadyAccountText: 'Already have an account?',
    loginLinkText: 'Login',
    registerSuccessHeading: 'Email Registration',
    registerSuccessMsg:
      'Please check your registered email and click verify email button to verify your email.',
  },
  login: {
    emailAddressLabel: 'E-Mail Address',
    passwordLabel: 'Password',
    emailErrorText: 'Please enter your registered email address',
    passwordErrorText: 'Please enter your password',
    forgotPasswordLinkText: 'Forgot Password?',
    loginText: 'Login',
    orText: 'OR',
    otherSignInText: 'Sign in using',
    noAccountText: "Don't have an account?",
    registerLinkText: 'Create Account',
    emailVerifySuccessHeading: 'Email Verification Successful',
    emailVerifySuccessMsg:
      'Your email has been verified. Click the below button to login into application',
  },
  forgotPassword: {
    header: 'Forgot Password?',
    emailAddressLabel: 'E-Mail Address',
    emailErrorText: 'Please enter your registered email address',
    resetPasswordMsg: "Click on 'Reset Password' button to get new password",
    successMsg:
      "New password is sent to your email address. If you don't see it in your inbox it might be in your spam folder.",
    resetPasswordBtnText: 'Reset Password',
  },
  auth: {
    noaccess: 'You do not have access to this page!',
    renewtoaccess: 'Please renew your subscription to access this page!',
  },
  homePage: {
    slide1Heading1: 'SAFE',
    slide1Heading2: 'BETTING',
    slide1Text1: 'WITH 100% RISK-FREE',
    slide1Text2: 'GUARANTEE',
    slide2Heading: 'Lorem Ipsum Dolor',
    slide3Heading: 'Sequi ea ut et est quaerat',
    dummyText:
      'Ut velit est quam dolor ad a aliquid qui aliquid. Sequi ea ut et est quaerat sequi nihil ut aliquam. Occaecati alias dolorem mollitia ut Similique ea voluptatem. Esse doloremque accusamus repellendus deleniti vel. Minus et tempore modi architecto.',
    comingSoonText: 'Coming Soon',
    winningAmountText: 'Winning Amount',
    entryText: '${pool_entry} Entry',
    maxPlayersText: 'Max. {pool_maxPlayer} Players',
    coinsNumberText: '{pool_coins} Shvrk Coins',
    noGamesAvailableMsg: 'No Games Available',
    playNowBtnText: 'Play Now',
    dailyPoolText: 'Daily Pool',
    extendedPoolText: 'Extended Pool',
    ownersPoolText: 'Owners Pool',
    winnersListHeading: 'Winners',
    howItWorkHeading1: "How It's",
    howItWorkHeading2: 'Work',
    contactUsHeading: 'Contact Us',
  },
  contactUs: {
    name: 'Your Name',
    email: 'Your Email',
    phone: 'Your Phone (Optional)',
    message: 'Your Message',
    nameErrorText: 'Enter your name',
    emailErrorText: 'Enter your email',
    phoneErrorText: 'Enter your phone number',
    messageErrorText: 'Type your message',
    emailInvalidErrorText: 'Your email address is invalid',
    nameInvalidErrorText: 'Your name is invalid',
    sendMessageBtnText: 'Send Message',
    successMessage: 'Message sent successfully',
  },
  games: {
    mainHeading: 'Game',
    poolText: 'Pool',
    sportTypesHeading: 'Sports',
    breadcrumbHome: 'Home',
    breadcrumbSeperator: '/',
    breadcrumbGame: 'Game',
    tabDailyPools: 'Daily Pools',
    tabExtendedPools: 'Extended Pools',
    pointsBalanceText: 'Points : ',
    startsAt: 'Starts At : ',
    endsAt: 'Ends At : ',
    tableHeading1: 'Games',
    tableHeading2: 'Point Spread',
    tableHeading3: 'Total Points',
    betMessage: 'Once bet is saved cannot be edited',
    tableHeading4: 'Money Line',
    homeTeam: '(Home Team)',
    awayTeam: '(Away Team)',
    noBetsAvailable: 'No Bets Available',
    ovText: 'OV:',
    unText: 'UN:',
    noPoolGame: 'No games found in this pool',
    leadingScorerHeading: 'Top Scorer {pool_name}',
    playersNumber: 'Players (10)',
    betSlipHeading: 'Bet Slip',
    tabStraightBet: 'Straight Bet',
    tabParlayBet: 'Parlay Bet',
    clearBetSlipBtnText: 'Clear',
    betInvalidValueMessage: 'Enter a valid value',
    removeBetText: 'x',
    totalWagerText: 'Total Wager:',
    oddsText: 'Odds:',
    pointText: 'POINT',
    potentialPayoutText: 'Potential Payout:',
    saveBtnText: 'Save',
    saveBetSuccess: 'Bet saved successfully',
    poolJoinSuccess: 'Pool Joined Successfully.',
    loginToBetText: 'Please login to make bet',
    noEnoughPoints: 'You do not have enough points',
    betNoticeHeading: 'Bet Notice',
    enterDailyPoolAskToConfirm: 'Do you want to enter the Daily pool?',
    enterDailyPoolMsg:
      'This will deduct $5 from your balance. You will get 1000 points to play this game.',
    enterDailyPoolConfirmBtnText: 'Yes Proceed!',
    enterDailyPoolCancelBtnText: 'Cancel',
    insufficientMoneyText: 'Insufficient Money',
    insufficientMoneyMsg:
      'Insufficient money is in the account. Please deposit your money',
    goDepositPageBtnText: 'Go to deposit',
    poolAmountText: 'Pool Entry Amount :',
    payUsingWalletText: 'Pay using your wallet',
    makePaymentHeading: 'Make Payment',
    payBtnText: 'Pay',
  },
  profile: {
    personalDetailsHeading: 'Personal Details',
    editDetailsHeading: 'Edit Details',
    helloText: 'Hello, ',
    currentBalanceText: 'Current Balance',
    withdrawLinkText: 'Withdraw',
    depositLinkText: 'Deposit',
    helpHeadingText: 'Need Help?',
    helpText:
      'Have questions or concerns regrading your account? Our experts are here to help!.',
    chatBtnText: 'Chat With Us',
    passwordErrorText: 'Please enter your password',
    nameText: 'Name',
    addressText: 'Address',
    cityText: 'City',
    stateText: 'State',
    countryText: 'Country',
    zipText: 'Zip',
    accountStatusText: 'Status',
    activeStatusText: 'Active',
    emailText: 'Email ID',
    mobileText: 'Mobile',
    editLinkText: 'Edit',
    updateProfilePhotoLabel: 'Update Profile Photo',
    uploadPhotoSuccessMsg:
      'Profile photo uploaded successfully. It will take some time to update',
    uploadBtnText: 'Upload',
    updateProfileBtnText: 'Update Profile',
    cancelBtnText: 'Cancel',
    updateProfileSuccess: 'Profile updated successfully',
    changePasswordHeading: 'Change Password',
    oldPasswordLabel: 'Old Password',
    newPasswordLabel: 'New Password',
    changePasswordSuccessMsg: 'Your password changed successfully.',
    changeBtnText: 'Change',
  },
  account: {
    accountHeadingText: 'My Account',
    viewProfileText: 'View Profile',
    addMoneyText: 'Add Money',
    addCardsText: 'Add Cards',
    withdrawText: 'Withdraw',
    activePoolsTab: 'Active Pools',
    previousPoolsTab: 'Previous Pools',
    currentGamesHeadingText: 'Current Games',
    tableHeadingDateText: 'Date',
    tableHeadingGamesText: 'Games',
    tableHeadingPoolText: 'Pool',
    tableHeadingBetTypesText: 'Bet',
    tableHeadingPointsText: 'Points',
    tableHeadingWinLooseText: 'Win/Loose',
    tableHeadingWinnerRankText: 'Winner Rank',
    tableHeadingAmountText: 'Winning Amount',
    tableHeadingTotalWagerText: 'Total Wager',
    tableHeadingOddsText: 'Odds',
    tableHeadingPayoutText: 'Potential Payout',
    betTypeParlayText: 'Parlay',
    editBetBtnText: 'Edit Bet',
    viewAllLinkText: 'View All',
    previousGamesHeadingText: 'Previous Games',
    noCurrentGamesBets: 'No bets for current games',
    noPreviousGamesBets: 'No bets for previous games',
    winNullText: '-',
    winText: 'Win',
    lostText: 'Lost',
    addMoneyHeadingText: 'Add Money',
    amountLabel: 'Amount',
    cardNumberLabel: 'Card Number',
    selectCardLabel: 'Select Card',
    defaultPaymentMethodLabel: 'Set as default payment method',
    youWillDeposit: 'You will deposit',
    addBtnText: 'Add',
    availableBalanceText: 'Available Balance',
    transactionFeesText: 'Transaction Fees',
    amountToWithdrawText: 'Amount To Withdraw',
    addBankAccountHeadingText: 'Add Bank Account Detail',
    bankNameLabel: 'Bank Name',
    accountHolderNameLabel: 'Account Holder Name',
    accountNumberLabel: 'Account Number',
    ifscCodeLabel: 'IFSC Code',
    confirmBankDetailLabel: 'I confirm the bank account details above',
    requestWithdrawBtnText: 'Request Withdraw',
    cardsForPaymentText: '(For Payments)',
    cardsHeadingText: 'Cards',
    cardHolderNameLabel: 'Card Holder Name',
    expirationMonthLabel: 'Expiry Month',
    expirationYearLabel: 'Expiry Year',
    validUpto: 'valid upto',
    CVVLabel: 'CVV',
    cardHolderNameErrorText: 'Please enter card holder name printed on card',
    cardNumberErrorText: 'Please enter card number printed on card',
    expirationMonthErrorText: 'Please enter card expiry month printed on card',
    expirationYearErrorText: 'Please enter card expiry year printed on card',
    CVVErrorText: 'Please enter card cvv printed on card',
    withdrawalRequestHeading1: 'Withdrawal Requests',
    withdrawalRequestHeading2: 'New withdrawal may take up to 24 to 48 hours',
    withdrawalRequestTableHeading1: 'Withdrawal Request Id',
    withdrawalRequestTableHeading2: 'Requested Amount',
    withdrawalRequestTableHeading3: 'Requested Currency',
    withdrawalRequestTableHeading4: 'Withdrawal Status',
    amountErrorText: 'Please enter amount',
    selectCardErrorText: 'Please select card',
    enterCVVErrorText: 'Please enter cvv',
    withdrawalAmountLimitErrorText:
      'Your wallet amount insufficient for withdrawal',
    noWithdrawalRequestText: 'No withdrawal request',
    cardDetailsLabel: 'Card Details',
    addCardText: 'Add Card',
    cardNumberHide: 'XXXX-XXXX-XXXX-',
    editCardText: 'Edit',
    defaultText: 'Default',
    setDefaultCardText: 'Set as Default',
    setDefaultCardSuccess: 'Default card changed successfully',
    deleteCardText: 'Delete',
    deleteCardTitle: 'Delete Card',
    deleteCardSuccess: 'Card deleted successfully.',
    noCard: 'No card found',
    cancelBtnText: 'Cancel',
    addNewCardSuccess: 'Card details added successfully',
    backToGamePage: 'Back to Game Page',
  },
  topHeader: {
    loginBtnText: 'LOGIN',
    signupBtnText: 'SIGNUP',
    currentBalanceText: 'Current Balance',
    pointsText: 'Points',
    balanceText: 'Wallet Balance',
    dropdownHeader1: 'Account',
    dropdownHeader2: 'Settings',
    dropdownDepositMenu: 'Deposit',
    dropdownWithdrawMenu: 'Withdraw',
    dropdownMyAccountMenu: 'My Account',
    dropdownProfileMenu: 'Profile',
    dropdownCardMenu: 'Add Cards',
    dropdownLockAccountMenu: 'Lock Account',
    dropdownLogoutMenu: 'Logout',
  },
};

export default LanguageTexts;
