import { gql } from '@apollo/client';

export const SIGNUP = gql`
  mutation signup($input: SignupInput!) {
    signup(input: $input) {
      _id
    }
  }
`;

export const LOGIN = gql`
  mutation login($input: LoginInput!) {
    login(input: $input) {
      access_token
    }
  }
`;

export const SOCIAL_LOGIN = gql`
  mutation socialLogin($id: String!) {
    socialLogin(id: $id) {
      _id
      access_token
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($input: ForgotPasswordInput!) {
    forgotPassword(input: $input)
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword($input: ChangePasswordInput!) {
    changePassword(input: $input)
  }
`;

export const WHOAMI = gql`
  query whoAmI {
    whoAmI {
      _id
      email
      firstName
      lastName
      profilePicUrl
      mobile
      wallet
      coins
    }
  }
`;

export const GET_ADDRESS = gql`
  query getAddress {
    getAddress {
      _id
      userId
      streetAddress
      countryId
      state
      city
      zip
    }
  }
`;

export const GET_COUNTRIES = gql`
  query countries {
    countries {
      _id
      name
      iso2
    }
  }
`;

export const GET_STATES = gql`
  query states($countryId: String!) {
    states(countryId: $countryId) {
      _id
      name
    }
  }
`;

export const GET_GAMES = gql`
  query listGames($pool: String!, $sportType: [String!]!) {
    listGames(pool: $pool, sportType: $sportType) {
      _id
      date
      awayTeam {
        name
      }
      homeTeam {
        name
      }
      sporttype {
        _id
        name
        value
      }
      bet {
        _id
        homeMoneyLine
        awayMoneyLine
        homePointSpread
        awayPointSpread
        homePointSpreadPayout
        awayPointSpreadPayout
        overUnder
        overPayout
        underPayout
      }
    }
  }
`;

export const GET_POOLS = gql`
  query listPools {
    listPools {
      name
      amount
      entry
      maxPlayer
      coins
      value
      startsAt
      expiresAt
    }
  }
`;

export const GET_SPORT_TYPES = gql`
  query listSportTypes {
    listSportTypes {
      _id
      name
      value
      icon
      comingSoon
    }
  }
`;

export const FETCH_USER_BETS = gql`
  query fetchUserBets($type: String!) {
    fetchUserBets(type: $type) {
      _id
      game
      pool
      betType
      betMainType
      isWin
      status
      sport
      points
      winPoints
      totalPoints
      createdAt
    }
  }
`;

export const FETCH_USER_BET = gql`
  query fetchUserBet($id: String!) {
    fetchUserBet(id: $id) {
      amount
      teamType
      team
      type
      sport
      betId
      gameId
      payload {
        _id
        awayMoneyLine
        homeMoneyLine
        awayPointSpread
        homePointSpread
        awayPointSpreadPayout
        homePointSpreadPayout
        overUnder
        overPayout
        underPayout
      }
    }
  }
`;
export const CHECK_USER_TRANSACTION = gql`
  query checkUserTransaction($input: CheckUserTransactionInput!) {
    checkUserTransaction(input: $input)
  }
`;

export const TRANSACTION_CALLBACK = gql`
  mutation transactionCallback($input: TransactionCallbackInput!) {
    transactionCallback(input: $input) {
      id
      amount
      type
    }
  }
`;

export const JOIN_POOL = gql`
  mutation joinPool($input: JoinPoolInput!) {
    joinPool(input: $input) {
      _id
      poolId
      coins
    }
  }
`;

export const ADD_MONEY_TO_WALLET = gql`
  mutation addMoneyToWallet($input: AddMoneyToWalletInput!) {
    addMoneyToWallet(input: $input)
  }
`;

export const DEDUCT_MONEY_FROM_WALLET = gql`
  mutation deductMoneyFromWallet($input: DeductMoneyFromWalletInput!) {
    deductMoneyFromWallet(input: $input)
  }
`;

export const SAVE_BET = gql`
  mutation addUserBet($input: BetInput!) {
    addUserBet(input: $input) {
      _id
    }
  }
`;

export const UPLOAD_PROFILE_PHOTO = gql`
  mutation updateCurrentUserProfilePic($input: UpdateProfilePicInput!) {
    updateCurrentUserProfilePic(input: $input) {
      profilePicUrl
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation updateProfile($input: UpdateProfileInput!) {
    updateProfile(input: $input) {
      firstName
      lastName
      mobile
      address {
        streetAddress
        city
        state
        countryId
        zip
      }
    }
  }
`;

export const LOAD_USER_CARD_INFO = gql`
  query listCard {
    listCard {
      clientRequestId
      paymentMethods {
        userPaymentOptionId
        paymentMethodName
        upoStatus
        upoData {
          ccCardNumber
          ccExpMonth
          ccExpYear
          ccNameOnCard
          brand
          isSelected
          cvv
        }
      }
    }
  }
`;

export const SET_DEFAULT_CARD = gql`
  mutation changeDefaultCard($input: ChangeDefaultCardInput!) {
    changeDefaultCard(input: $input) {
      id
    }
  }
`;

export const DELETE_CARD = gql`
  mutation deleteCard($input: ChangeDefaultCardInput!) {
    deleteCard(input: $input) {
      status
    }
  }
`;

export const OPEN_PAYMENT_ORDER = gql`
  mutation openPaymentOrder($input: OpenPaymentOrderInput!) {
    openPaymentOrder(input: $input) {
      sessionToken
      clientRequestId
      userTokenId
      clientUniqueId
      amount
    }
  }
`;

export const GENERATE_WITHDRAW_URL = gql`
  mutation generateWithdrawUrl($input: OpenPaymentOrderInput!) {
    generateWithdrawUrl(input: $input) {
      url
    }
  }
`;

export const GET_WITHDRAWAL_REQUESTS = gql`
  query getWithdrawalRequests {
    getWithdrawalRequests {
      wdRequestId
      requestedAmount
      requestedCurrency
      wdRequestStatus
    }
  }
`;

export const ADD_CONTACT_US = gql`
  mutation addContactUs($input: ContactUsInput!) {
    addContactUs(input: $input)
  }
`;

export const MY_ONGOING_POOL = gql`
  query myOngoingPool($input: MyOngoingPoolInput!) {
    myOngoingPool(input: $input) {
      _id
      poolId
      coins
    }
  }
`;

export const ALL_USER_ONGOING_POOL = gql`
  query allUserOngoingPool {
    allUserOngoingPool {
      _id
      poolId
      pool {
        _id
        name
        value
      }
      userId
      user {
        _id
        firstName
        lastName
        profilePicUrl
      }
      initCoins
      coins
      entryAmount
    }
  }
`;

export const MY_POOL_BETS = gql`
  query myPoolBets($type: String!) {
    myPoolBets(type: $type) {
      _id
      poolId
      pool {
        _id
        name
        value
      }
      initCoins
      coins
      entryAmount
      winnerRank
      winningAmount
      createdAt
      userBets {
        _id
        points
        winPoints
        totalPoints
        isWin
        isRefundable
        payload {
          betId
          bet {
            _id
            homeMoneyLine
            awayMoneyLine
            homePointSpread
            awayPointSpread
            homePointSpreadPayout
            awayPointSpreadPayout
            overUnder
            overPayout
            underPayout
          }
          gameId
          game {
            date
            awayTeam {
              name
            }
            homeTeam {
              name
            }
            sporttype {
              name
            }
            isCancelled
          }
          betTypeId
          betType {
            _id
            name
            value
          }
          teamType
          odds
          isWin
          isRefundable
        }
      }
    }
  }
`;

export const GET_USER_COUNT_IN_POOL = gql`
  query getUserCountInPool($input: GetUserCountInPoolInput!) {
    getUserCountInPool(input: $input)
  }
`;
