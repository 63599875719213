/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { Field, Form, Formik } from 'formik';
import $ from 'jquery';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';

import {
  AppRoutePath,
  BetTypes,
  PublicRoutePath,
  TeamTypes,
  TransactionType,
} from '../../common/constants';
import { loadSafeCharge } from '../../common/helper';
import useUserOngoingPools from '../../common/hooks';
import LanguageTexts from '../../common/language';
import {
  BetInput,
  BetPayloadInput,
  OpenOrderModel,
  PoolModel,
  RootState,
  SelectedBet,
} from '../../common/types';
import PoolUsersList from '../../components/PoolUsersList';
import {
  loadPools,
  loadSportTypes,
  resetLoadSportTypesSuccess,
} from '../home/home.slice';
import { whoami } from '../login/login.slice';
import {
  deductMoneyFromWallet,
  loadCardInfo,
  openPaymentOrder,
  resetDeductMoneyFromWalletSuccess,
  resetOpenPaymentOrder,
} from '../profile/profile.slice';
import {
  allUserOngoingPools,
  getUserCountInPool,
  joinPool,
  loadGames,
  myOngoingPool,
  resetJoinPoolSuccess,
  resetSaveUserBetSuccess,
  resetTransactionCallbackSuccess,
  saveUserBet,
  transactionCallback,
} from './games.slice';

const getOddsFromType = (type: string, payload: any, teamType: string) => {
  if (type === BetTypes.PS) {
    return teamType === TeamTypes.HOME
      ? payload.homePointSpreadPayout
      : payload.awayPointSpreadPayout;
  }
  if (type === BetTypes.ML) {
    return teamType === TeamTypes.HOME
      ? payload.homeMoneyLine
      : payload.awayMoneyLine;
  }
  return teamType === TeamTypes.HOME ? payload.overPayout : payload.underPayout;
};

const GamePage: React.FC = (): JSX.Element => {
  const {
    app: appTxt,
    games: gamesTxt,
    login: loginTxt,
    account: accountTxt,
  } = LanguageTexts;

  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();

  // eslint-disable-next-line prefer-destructuring
  const params: any = match.params;

  // global state
  const {
    games,
    betState,
    myOngoingPoolModel,
    allUserOngoingPools: allUserOngoingPoolsList,
    userCountInPool,
    transaction,
    transactionCallbackState: { success: transactionCallbackSuccess },
    joinPoolState: { success: joinPoolSuccess },
  } = useSelector((state: RootState) => state.game);
  const { sportTypes, sportTypeState } = useSelector(
    (state: RootState) => state.home,
  );
  const { user } = useSelector((state: RootState) => state.login);
  const { pools } = useSelector((state: RootState) => state.home);
  const {
    userCardInfo,
    openOrder,
    openPaymentOrder: { success: openPaymentOrderSuccess },
    deductMoneyFromWalletState: { success: deductMoneyFromWalletSuccess },
  } = useSelector((state: RootState) => state.profile);

  // local state
  const [loadRequested, setLoadRequested] = useState(false);
  const [tab, setTab] = useState(params.pool);
  const [betTab, setBetTab] = useState('Straight');
  const [selectedSportType, setSelectedSportType] = useState<string[]>([]);
  const [bets, setBets] = useState<SelectedBet[]>([]);
  const [error, setError] = useState<any>();
  const [parlayAmount, setParlayAmount] = useState('0');
  const [homeTeamSelected, setHomeTeamSelected] = useState<string[]>([]);
  const [awayTeamSelected, setAwayTeamSelected] = useState<string[]>([]);
  const [betLoading, setBetLoading] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [cvv, setCvv] = useState();
  const [safeCharge, setSafeCharge] = useState<any>();
  const [selectedPaymentOption, setSelectedPaymentOption] = useState<any>();

  const getActivePool = useCallback((): PoolModel | undefined => {
    return pools?.find((item) => item.value === params.pool);
  }, [pools, params.pool]);
  const activePool = getActivePool();

  const allUserOngoingPoolsByPool = useUserOngoingPools(
    allUserOngoingPoolsList || [],
  );

  const activePoolUsers = useMemo(() => {
    if (activePool && allUserOngoingPoolsByPool[activePool.value]) {
      return allUserOngoingPoolsByPool[activePool.value].usersByRank;
    }

    return [];
  }, [activePool, allUserOngoingPoolsByPool]);

  useEffect(() => {
    if (!loadRequested) {
      dispatch(loadSportTypes());
      dispatch(loadPools());
      dispatch(loadCardInfo());
      setLoadRequested(true);
    }
  }, [loadRequested, dispatch]);

  useEffect(() => {
    dispatch(myOngoingPool(params.pool));
    dispatch(getUserCountInPool(params.pool));
    dispatch(allUserOngoingPools());
  }, [dispatch, params.pool]);

  useEffect(() => {
    dispatch(loadGames({ pool: params.pool, sportType: selectedSportType }));
  }, [dispatch, params.pool, selectedSportType]);

  useEffect(() => {
    loadSafeCharge().then((safe) => {
      setSafeCharge(safe);
    });
  }, []);

  useEffect(() => {
    if (betState.success) {
      dispatch(resetSaveUserBetSuccess());
      dispatch(whoami());
      setBetLoading(false);
      toast.success(gamesTxt.saveBetSuccess, {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      history.push(AppRoutePath.Account);
    }
  }, [betState.success, dispatch, gamesTxt.saveBetSuccess, history]);

  useEffect(() => {
    if (sportTypeState.success && sportTypes && sportTypes.length > 0) {
      dispatch(resetLoadSportTypesSuccess());
      const sportType: any = sportTypes[0].value;
      setSelectedSportType([sportType]);
      dispatch(loadGames({ pool: params.pool, sportType: [sportType] }));
    }
  }, [sportTypeState.success, sportTypes, dispatch, params.pool]);

  // payment stuff
  const paymentModeWallet =
    user && activePool && user.wallet >= activePool.entry;
  let cards: any = [];
  if (userCardInfo) {
    cards = userCardInfo.paymentMethods.map((item) => {
      return {
        value: item.userPaymentOptionId,
        label: item.upoData.ccCardNumber,
      };
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function onPaymentOptionChange(val: any) {
    setSelectedPaymentOption(val);
  }

  useEffect(() => {
    const createPayment = (data: OpenOrderModel) => {
      try {
        if (
          selectedPaymentOption &&
          selectedPaymentOption.value &&
          safeCharge
        ) {
          safeCharge.createPayment(
            {
              sessionToken: data.sessionToken,
              userTokenId: data.userTokenId,
              clientUniqueId: data.clientUniqueId,
              clientRequestId: data.clientRequestId,
              amount: data.amount,
              currency: 'USD',
              paymentOption: {
                userPaymentOptionId: selectedPaymentOption.value,
                card: {
                  CVV: cvv,
                },
              },
            },
            (resp: any) => {
              dispatch(
                transactionCallback({
                  type: TransactionType.POOL_JOIN,
                  amount: parseInt(data.amount, 10),
                  payload: JSON.stringify({
                    paymentResponse: resp,
                    pool: params.pool,
                  }),
                }),
              );
            },
          );
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    };

    if (openPaymentOrderSuccess) {
      if (openOrder) {
        dispatch(resetOpenPaymentOrder());
        createPayment(openOrder);
      }
    }
  }, [
    openPaymentOrderSuccess,
    openOrder,
    cvv,
    dispatch,
    params.pool,
    safeCharge,
    selectedPaymentOption,
  ]);

  useEffect(() => {
    if (transactionCallbackSuccess && transaction) {
      dispatch(
        joinPool({ poolId: params.pool, transactionId: transaction.id }),
      );
      dispatch(resetTransactionCallbackSuccess());
    }
  }, [dispatch, transactionCallbackSuccess, params.pool, transaction]);

  useEffect(() => {
    if (activePool && deductMoneyFromWalletSuccess) {
      dispatch(
        transactionCallback({
          type: TransactionType.DEDUCT_POOL_AMOUNT_FROM_WALLET,
          amount: activePool.entry,
          payload: JSON.stringify({
            pool: activePool.value,
          }),
        }),
      );
      dispatch(whoami());
      dispatch(resetDeductMoneyFromWalletSuccess());
    }
  }, [dispatch, deductMoneyFromWalletSuccess, activePool]);

  useEffect(() => {
    if (joinPoolSuccess) {
      $('.payment-modal-close').trigger('click');
      toast.success(gamesTxt.poolJoinSuccess, {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setPaymentLoading(false);
      dispatch(resetJoinPoolSuccess());
      dispatch(whoami());
      dispatch(getUserCountInPool(params.pool));
    }
  }, [
    dispatch,
    gamesTxt.poolJoinSuccess,
    joinPoolSuccess,
    params.pool,
    transaction,
  ]);

  const pay = useCallback(
    (values: any) => {
      if (!activePool) return;

      setPaymentLoading(true);

      if (paymentModeWallet) {
        dispatch(deductMoneyFromWallet({ amount: activePool.entry }));
      } else {
        setCvv(values.cvv);
        dispatch(openPaymentOrder({ amount: activePool.entry }));
      }
    },
    [paymentModeWallet, activePool, dispatch],
  );

  const validatePayment = useCallback(
    (values: any) => {
      const errors: any = {};

      if (paymentModeWallet) {
        return errors;
      }

      if (!values.cvv) {
        errors.cvv = accountTxt.enterCVVErrorText;
      }

      if (!selectedPaymentOption) {
        errors.cvv = accountTxt.selectCardErrorText;
      }

      return errors;
    },
    [paymentModeWallet, selectedPaymentOption, accountTxt],
  );

  // game bet stuff
  const clearBet = () => {
    setBets([]);
    setHomeTeamSelected([]);
    setAwayTeamSelected([]);
    setParlayAmount('0');
    setError(false);
  };

  const onStraightBetAmountChange = (index: number, value: string) => {
    const val = +value;

    setBets((prev) => {
      const newVal = [...prev];

      if (val > 0) {
        newVal[index] = { ...newVal[index], amount: val, isError: false };
      } else {
        newVal[index] = { ...newVal[index], amount: undefined, isError: true };
      }

      return newVal;
    });
  };

  const onParleyAmountChange = (value: string) => {
    const val = +value;

    if (val > 0) {
      setParlayAmount(value);
      setError(false);
    } else {
      setError(true);
      setParlayAmount('');
    }
  };

  const getSingleStraightBetPoint = (betItem: SelectedBet) => {
    const odd = getOddsFromType(
      betItem.type,
      betItem.payload,
      betItem.teamType,
    );
    const value = betItem.amount || 0;

    const oddPoints =
      parseFloat(odd) < 0
        ? (value * 100) / Math.abs(parseFloat(odd))
        : (value * parseFloat(odd)) / 100;

    return {
      points: Math.round(value),
      oddPoints: Math.round(oddPoints),
      totalPoints: Math.round(value + oddPoints),
    };
  };

  const getStraightBetPoints = useCallback(() => {
    const totalList = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const betItem of bets) {
      totalList.push(getSingleStraightBetPoint(betItem));
    }

    const points = totalList.reduce((prev, cur) => prev + cur.points, 0);

    const odds = totalList.reduce((prev, cur) => prev + cur.oddPoints, 0);

    const totalBetPoints = totalList.reduce(
      (prev, cur) => prev + cur.totalPoints,
      0,
    );

    return {
      points,
      oddPoints: odds,
      totalBetPoints,
    };
  }, [bets]);

  const getParlayBetPoints = useCallback(() => {
    if (parlayAmount) {
      let fractionalPoints = 1;
      bets.forEach((item) => {
        const oddPoints = getOddsFromType(
          item.type,
          item.payload,
          item.teamType,
        );
        const df =
          parseFloat(oddPoints) < 0
            ? 100 / Math.abs(parseFloat(oddPoints))
            : parseFloat(oddPoints) / 100;
        fractionalPoints *= 1 + df;
      });
      const points = Math.round(parseFloat(parlayAmount));
      const totalBetPoints = Math.round(points * fractionalPoints);
      const oddPoints = totalBetPoints - points;

      return {
        points,
        oddPoints,
        totalBetPoints,
      };
    }

    return {
      points: 0,
      oddPoints: 0,
      totalBetPoints: 0,
    };
  }, [bets, parlayAmount]);

  const parleyPoints = getParlayBetPoints();
  const straightPoints = getStraightBetPoints();

  const handleSportType = useCallback(
    (type: string) => {
      if (selectedSportType.indexOf(type) >= 0) {
        setSelectedSportType((prev) => {
          const newVal = [...prev];
          newVal.splice(newVal.indexOf(type), 1);
          dispatch(loadGames({ pool: params.pool, sportType: newVal }));
          return newVal;
        });

        const keepBets = bets
          .filter((item) => {
            return item.sport !== type;
          })
          .map((item) => {
            return { ...item };
          });
        const newHTSel: string[] = [];
        const newATSel: string[] = [];

        keepBets.forEach((item) => {
          if (item.teamType === TeamTypes.HOME) {
            newHTSel.push(item.betId);
          } else {
            newATSel.push(item.betId);
          }
        });

        setBets(keepBets);
        setHomeTeamSelected(newHTSel);
        setAwayTeamSelected(newATSel);
      } else {
        setSelectedSportType((prev) => {
          const newVal = [...prev];
          newVal.push(type);
          dispatch(loadGames({ pool: params.pool, sportType: newVal }));
          return newVal;
        });
      }
    },
    [selectedSportType, bets, dispatch, params.pool],
  );

  const onSelectBet = useCallback(
    (
      team: string,
      teamType: string,
      type: string,
      payload: any,
      betId: string,
      gameId: string,
      sport: string,
    ) => {
      if (!user) {
        toast.error(gamesTxt.loginToBetText, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        history.push(PublicRoutePath.Login);
        return;
      }
      if (!myOngoingPoolModel) {
        const pool = pools?.find((item) => item.value === params.pool);

        if (pool) {
          if (pool.startsAt) {
            const poolStartsAt = moment(pool.startsAt);
            toast.error(
              `${
                pool.name
              } is not Active. Next Pool will start at ${poolStartsAt.format(
                'DD/MM/YYYY hh:mm A',
              )}`,
              {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              },
            );

            return;
          }

          if (pool.expiresAt) {
            const poolExpiresAt = moment(pool.expiresAt);

            if (poolExpiresAt.isBefore()) {
              toast.error(
                `${pool.name} is Expired. Wait for next pool to start.`,
                {
                  position: 'top-center',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                },
              );
              return;
            }
          }

          if (userCountInPool != null && userCountInPool < pool.maxPlayer) {
            $('.pool-model-open').trigger('click');
          } else {
            toast.error(`${pool.name} is Full.`, {
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
      } else if (myOngoingPoolModel.coins > 0) {
        const item: SelectedBet = {
          team,
          teamType,
          type,
          betId,
          gameId,
          sport,
          amount: 0,
          payload,
          isError: false,
        };

        switch (teamType) {
          case TeamTypes.HOME:
            if (homeTeamSelected.indexOf(betId) === -1) {
              setHomeTeamSelected((prev) => [...prev, betId]);
              setBets((prev) => {
                const newVal = [...prev, item];

                if (newVal.length > 1) {
                  setBetTab('Parlay');
                } else {
                  setBetTab('Straight');
                }

                return newVal;
              });
            } else {
              const filteredHomeBetIds = homeTeamSelected.filter((itemIn) => {
                return itemIn !== betId;
              });

              setHomeTeamSelected(filteredHomeBetIds);
              setBets((prev) => {
                const newVal = [...prev];
                const newItem = newVal.filter((itemIn) => {
                  return (
                    itemIn.betId !== betId || itemIn.teamType !== TeamTypes.HOME
                  );
                });
                if (newItem.length > 1) {
                  setBetTab('Parlay');
                } else {
                  setBetTab('Straight');
                }
                return newItem;
              });
            }
            break;
          case TeamTypes.AWAY:
            if (awayTeamSelected.indexOf(betId) === -1) {
              setAwayTeamSelected((prev) => [...prev, betId]);

              setBets((prev) => {
                const newVal = [...prev, item];

                if (newVal.length > 1) {
                  setBetTab('Parlay');
                } else {
                  setBetTab('Straight');
                }

                return newVal;
              });
            } else {
              const filteredAwayBetIds = awayTeamSelected.filter((itemIn) => {
                return itemIn !== betId;
              });

              setAwayTeamSelected(filteredAwayBetIds);
              setBets((prev) => {
                const newVal = [...prev];
                const newItem = newVal.filter((itemIn) => {
                  return (
                    itemIn.betId !== betId || itemIn.teamType !== TeamTypes.AWAY
                  );
                });
                if (newItem.length > 1) {
                  setBetTab('Parlay');
                } else {
                  setBetTab('Straight');
                }
                return newItem;
              });
            }
            break;
          default:
            break;
        }
      } else {
        toast.error(gamesTxt.noEnoughPoints, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    },
    [
      myOngoingPoolModel,
      homeTeamSelected,
      awayTeamSelected,
      params.pool,
      pools,
      userCountInPool,
      user,
      history,
      gamesTxt,
    ],
  );

  const remove = useCallback(
    (index: number, teamType: string, betId: string) => {
      setBets((prev) => {
        const newVal = [...prev];
        newVal.splice(index, 1);
        if (newVal.length > 1) {
          setBetTab('Parlay');
        } else {
          setBetTab('Straight');
        }
        return newVal;
      });

      if (teamType === TeamTypes.HOME) {
        const filteredHomeBetIds = homeTeamSelected.filter((item) => {
          return item !== betId;
        });

        setHomeTeamSelected(filteredHomeBetIds);
      } else {
        const filteredAwayBetIds = awayTeamSelected.filter((item) => {
          return item !== betId;
        });

        setAwayTeamSelected(filteredAwayBetIds);
      }
    },
    [homeTeamSelected, awayTeamSelected],
  );

  const saveBet = useCallback(
    (input: BetInput) => {
      setBetLoading(true);
      dispatch(saveUserBet(input));
    },
    [dispatch],
  );

  const save = useCallback(() => {
    if (betTab === 'Parlay') {
      if (!parlayAmount || (parlayAmount && +parlayAmount <= 0)) {
        setError(true);
      } else {
        setError(false);
        const payloadParams: BetPayloadInput[] = [];
        bets.forEach((item) => {
          payloadParams.push({
            betTypeId: item.type,
            betId: item.betId.substring(item.betId.indexOf('-') + 1),
            gameId: item.gameId,
            teamType: item.teamType,
            odds: getOddsFromType(item.type, item.payload, item.teamType),
          });
        });

        if (
          myOngoingPoolModel &&
          parleyPoints.points > myOngoingPoolModel.coins
        ) {
          toast.error(
            `You can bet maximum ${myOngoingPoolModel.coins} coins.`,
            {
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            },
          );
          return;
        }

        const paramsOp: any = {
          pool: tab,
          betType: BetTypes.PARLAY,
          points: parleyPoints.points,
          totalPoints: parleyPoints.totalBetPoints,
          winPoints: parleyPoints.oddPoints,
          payload: payloadParams,
        };

        saveBet(paramsOp);
      }
    } else {
      const amountErrors = bets.filter(({ amount: am }) => {
        return !am || (am && +am <= 0);
      });
      const amountErrorIds = bets.map(({ amount: am, betId }) => {
        return !am || (am && +am <= 0) ? betId : null;
      });

      if (amountErrors.length > 0) {
        const allBets = bets.map((item) => {
          return {
            ...item,
            isError: amountErrorIds.indexOf(item.betId) >= 0,
          };
        });

        setBets(allBets);
      } else {
        if (
          myOngoingPoolModel &&
          straightPoints.points > myOngoingPoolModel.coins
        ) {
          toast.error(
            `You can bet maximum ${myOngoingPoolModel.coins} coins.`,
            {
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            },
          );
          return;
        }
        bets.forEach((item) => {
          const itemBetPoint = getSingleStraightBetPoint(item);
          const paramsOp: any = {
            pool: tab,
            betType: item.type,
            points: itemBetPoint.points,
            totalPoints: itemBetPoint.totalPoints,
            winPoints: itemBetPoint.oddPoints,
            payload: [
              {
                betTypeId: item.type,
                betId: item.betId.substring(item.betId.indexOf('-') + 1),
                gameId: item.gameId,
                teamType: item.teamType,
                odds: getOddsFromType(item.type, item.payload, item.teamType),
              },
            ],
          };

          saveBet(paramsOp);
        });
      }
    }
  }, [
    parleyPoints,
    straightPoints,
    betTab,
    bets,
    myOngoingPoolModel,
    parlayAmount,
    tab,
    saveBet,
  ]);

  // tab stuff
  const changeTab = (sTab: string) => {
    setTab(sTab);
    clearBet();
    history.replace(`/games/${sTab}`);
  };

  const changeBetTab = (sTab: string) => {
    setBetTab(sTab);
  };

  // scroll/responsive stuff
  useEffect(() => {
    function handleScroll() {
      const betslipBox = document.getElementById('betslip-box');
      if (window.innerWidth > 992) {
        if (window.scrollY >= 300) {
          betslipBox?.classList.add('stickey-box');
        } else {
          betslipBox?.classList.remove('stickey-box');
        }
      }
    }

    window.addEventListener('scroll', handleScroll);
  });

  useEffect(() => {
    function handleBetslipBox() {
      const betslipBox = document.getElementById('betslip-box');
      const betslipBoxItems = document.getElementById('betslip-box-items');
      if (window.innerWidth <= 992) {
        betslipBox?.classList.add('stickey-box-small-screen');
        betslipBoxItems?.classList.add('betslip-box-items-small-screen');
      } else {
        betslipBox?.classList.remove('stickey-box-small-screen');
        betslipBoxItems?.classList.remove('betslip-box-items-small-screen');
      }
    }

    window.addEventListener('resize', handleBetslipBox);
  });

  useEffect(() => {
    function handleLeaderScorerBox() {
      const winnersListGroup = document.getElementById('winners-list-group');
      const winnersList = document.getElementById('winners-list');
      if (window.innerWidth <= 992) {
        winnersListGroup?.classList.add('winners-list-group-small-screen');
        winnersList?.classList.add('winners-list-small-screen');
      } else {
        winnersListGroup?.classList.remove('winners-list-group-small-screen');
        winnersList?.classList.remove('winners-list-small-screen');
      }
    }

    window.addEventListener('resize', handleLeaderScorerBox);
  });

  useEffect(() => {
    function handleLeftGameMenubar() {
      const leftGameMenubar = document.getElementById('left-game-menubar');
      if (window.innerWidth <= 992) {
        leftGameMenubar?.classList.add('sport-types-menubar');
      } else {
        leftGameMenubar?.classList.remove('sport-types-menubar');
      }
    }

    window.addEventListener('resize', handleLeftGameMenubar);
  });

  function toggleBetSlipBox(e: React.MouseEvent) {
    e.preventDefault();
    const betslipBoxItems = document.getElementById('betslip-box-items');
    const toggleIcon = document.getElementById('toggle-icon');
    if (
      !betslipBoxItems?.classList.contains('betslip-box-items-small-screen') &&
      toggleIcon?.classList.contains('fa-chevron-down')
    ) {
      betslipBoxItems?.classList.add('betslip-box-items-small-screen');
      toggleIcon.classList.remove('fa-chevron-down');
      toggleIcon.classList.add('fa-chevron-up');
    } else {
      betslipBoxItems?.classList.remove('betslip-box-items-small-screen');
      toggleIcon?.classList.remove('fa-chevron-up');
      toggleIcon?.classList.add('fa-chevron-down');
    }
  }

  function toggleLeadingScorerBox(e: React.MouseEvent) {
    e.preventDefault();
    const winnersList = document.getElementById('winners-list');
    const toggleIcon = document.getElementById('leading-scorer-toggle-icon');
    if (
      !winnersList?.classList.contains('winners-list-small-screen') &&
      toggleIcon?.classList.contains('fa-chevron-down')
    ) {
      winnersList?.classList.add('winners-list-small-screen');
      toggleIcon.classList.remove('fa-chevron-down');
      toggleIcon.classList.add('fa-chevron-up');
    } else {
      winnersList?.classList.remove('winners-list-small-screen');
      toggleIcon?.classList.remove('fa-chevron-up');
      toggleIcon?.classList.add('fa-chevron-down');
    }
  }

  return (
    <>
      <main id="main" className="innerpage game-page leftsidebar-main">
        <div className="main-game-section">
          <div
            id="left-game-menubar"
            className={
              window.innerWidth <= 992
                ? `left-game-menubar sport-types-menubar`
                : `left-game-menubar`
            }
          >
            <div className="page-wrapper">
              <nav id="sidebar" className="sidebar-wrapper">
                <div className="sidebar-content">
                  <div id="sidebar-brand" className="sidebar-brand">
                    <h4>{gamesTxt.sportTypesHeading}</h4>
                  </div>
                  <div id="sidebar-menu" className="sidebar-menu">
                    <ul>
                      {sportTypes &&
                        sportTypes.length > 0 &&
                        sportTypes.map((sportType, index) => {
                          return (
                            <li
                              key={`${sportType._id}`}
                              id="sport-types-checkbox"
                            >
                              <div className="form-group">
                                <div className="custom-checkbox custom-control">
                                  <input
                                    type="checkbox"
                                    id={`sportType-${index}`}
                                    onChange={() =>
                                      handleSportType(sportType.value)
                                    }
                                    checked={
                                      selectedSportType.indexOf(
                                        sportType.value,
                                      ) >= 0
                                    }
                                    className="custom-control-input"
                                  />
                                  <label
                                    htmlFor={`sportType-${index}`}
                                    className="custom-control-label"
                                  >
                                    {sportType.value.toUpperCase()}
                                  </label>
                                </div>
                              </div>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
          </div>
          <section id="mainGameSection" className="main-bet-section bg-primary">
            <div className="breadcrumb-section bg-black">
              <div className="breadcrumb">
                <Link to="/">{gamesTxt.breadcrumbHome}</Link>
                <span> {gamesTxt.breadcrumbSeperator} </span>
                <span className="breadcrumb__end">
                  {gamesTxt.breadcrumbGame}
                </span>
              </div>
            </div>
            <div className="container-fluid betgame-section">
              <div className="game-pool-lists">
                <div className="row">
                  <div className="col-12 mb-3 d-flex align-items-center">
                    <h1 className="game-title text-white d-inline">
                      {gamesTxt.mainHeading}
                    </h1>
                    <span className="ml-2 bet-message">
                      ({gamesTxt.betMessage})
                    </span>
                  </div>
                  <div className="col-12 list-tab">
                    <div className="row d-flex flex-lg-row align-items-lg-center flex-column justify-content-center">
                      <div className="col-12 mb-4">
                        <div
                          className="list-group flex-direction-row bet-tab-list"
                          id="list-tab"
                          role="tablist"
                        >
                          {pools &&
                            pools.length > 0 &&
                            pools.map((pool) => {
                              return pool.name !== 'Daily Pool (India)' &&
                                pool.name !== 'Extended Pool (India)' ? (
                                <a
                                  key={`key${pool.name}`}
                                  className={
                                    tab === pool.value
                                      ? 'list-group-item list-group-item-action active w-auto'
                                      : 'list-group-item list-group-item-action w-auto'
                                  }
                                  id="list-home-list"
                                  data-toggle="list"
                                  href="javascript;"
                                  onClick={() => changeTab(pool.value)}
                                  role="tab"
                                  aria-controls="home"
                                >
                                  {pool.name}
                                </a>
                              ) : null;
                            })}
                        </div>
                      </div>
                    </div>
                    <div className="row d-flex flex-lg-row align-items-lg-center flex-column justify-content-center">
                      <div className="col-xl-6 col-12 mb-xl-0 mb-2">
                        {activePool?.startsAt ? (
                          <p className="p-2 pool-time">
                            <b>
                              {activePool?.name} {gamesTxt.startsAt}
                            </b>
                            {moment(activePool.startsAt).format(
                              'MM/DD/YYYY - hh:mm A',
                            )}
                          </p>
                        ) : (
                            ''
                          )}
                        {activePool?.expiresAt ? (
                          <p className="p-2 pool-time">
                            <b>
                              {activePool?.name} {gamesTxt.endsAt}
                            </b>
                            {moment(activePool.expiresAt).format(
                              'MM/DD/YYYY - hh:mm A',
                            )}
                          </p>
                        ) : (
                            ''
                          )}
                      </div>
                      <div className="col-xl-6 col-12 mb-2 text-xl-right">
                        <span className="p-2 current-points-balance">
                          {activePool?.name} {gamesTxt.pointsBalanceText}{' '}
                          <span className="font-weight-600">
                            {myOngoingPoolModel ? Number(myOngoingPoolModel.coins.toFixed(0)).toLocaleString().split(/\s/).join(',') : 0}
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="tab-content" id="nav-tabContent">
                      <div
                        className="tab-pane fade show active pool-game-bet-list"
                        id="list-daily-pools"
                        role="tabpanel"
                        aria-labelledby="list-home-list"
                      >
                        <button
                          data-target="#payment-modal"
                          data-toggle="modal"
                          className="pool-model-open d-none"
                          type="button"
                        >
                          {gamesTxt.makePaymentHeading}
                        </button>
                        {games && games.length > 0 ? (
                          games.map((game) => {
                            return (
                              <div
                                key={game._id}
                                className="table-responsive mt-4 bg-white nba-final-listbox game-listbox border-radius-10"
                              >
                                <table className="table m-0">
                                  <thead>
                                    <tr className="table-header-row">
                                      <th className="text-center">
                                        <span className="text-uppercase">
                                          {gamesTxt.tableHeading1}
                                        </span>
                                      </th>
                                      <th className="text-center">
                                        <span className="text-uppercase">
                                          {gamesTxt.tableHeading2}
                                        </span>
                                      </th>
                                      <th className="text-center">
                                        <span className="text-uppercase">
                                          {gamesTxt.tableHeading3}
                                        </span>
                                      </th>
                                      <th className="text-center">
                                        <span className="text-uppercase">
                                          {gamesTxt.tableHeading4}
                                        </span>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td colSpan={4}>
                                        <div className="game-datetime text-center py-3">
                                          <span>
                                            {moment(game.date).format(
                                              'MM/DD/YYYY hh:mm A',
                                            )}
                                          </span>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div className="game-name text-center">
                                          <span>{game.awayTeam.name}</span>
                                          <br />
                                          <span className="text-muted small">
                                            {gamesTxt.awayTeam}
                                          </span>
                                        </div>
                                      </td>
                                      <td className="text-center">
                                        {game.bet ? (
                                          <div
                                            className={
                                              awayTeamSelected.indexOf(
                                                `ps-${game.bet._id}`,
                                              ) >= 0 && tab === params.pool
                                                ? 'action-td active'
                                                : 'action-td'
                                            }
                                            onClick={() =>
                                              onSelectBet(
                                                game.awayTeam.name,
                                                TeamTypes.AWAY,
                                                BetTypes.PS,
                                                game.bet,
                                                `ps-${game.bet._id}`,
                                                game._id,
                                                game.sporttype.value,
                                              )
                                            }
                                          >
                                            <span className="bet-num">
                                              {game.bet &&
                                                (game.bet.awayPointSpread < 0
                                                  ? ''
                                                  : '+') +
                                                  game.bet.awayPointSpread}
                                            </span>
                                            <span className="bet-num outcome-num badge badge-success">
                                              {game.bet &&
                                                (game.bet
                                                  .awayPointSpreadPayout < 0
                                                  ? ''
                                                  : '+') +
                                                  game.bet
                                                    .awayPointSpreadPayout}
                                            </span>
                                          </div>
                                        ) : (
                                          <p className="text-secondary">
                                            {gamesTxt.noBetsAvailable}
                                          </p>
                                        )}
                                      </td>
                                      <td className="text-center">
                                        {game.bet ? (
                                          <div
                                            className={
                                              awayTeamSelected.indexOf(
                                                `tp-${game.bet._id}`,
                                              ) >= 0 && tab === params.pool
                                                ? 'action-td active'
                                                : 'action-td'
                                            }
                                            onClick={() =>
                                              onSelectBet(
                                                game.awayTeam.name,
                                                TeamTypes.AWAY,
                                                BetTypes.TP,
                                                game.bet,
                                                `tp-${game.bet._id}`,
                                                game._id,
                                                game.sporttype.value,
                                              )
                                            }
                                          >
                                            <span className="bet-num">
                                              {gamesTxt.unText}{' '}
                                              {game.bet && game.bet.overUnder}
                                            </span>
                                            <span className="bet-num outcome-num badge badge-success">
                                              {game.bet &&
                                                (game.bet.underPayout < 0
                                                  ? ''
                                                  : '+') + game.bet.underPayout}
                                            </span>
                                          </div>
                                        ) : (
                                          <p className="text-secondary">
                                            {gamesTxt.noBetsAvailable}
                                          </p>
                                        )}
                                      </td>
                                      <td className="text-center">
                                        {game.bet ? (
                                          <div
                                            className={
                                              awayTeamSelected.indexOf(
                                                `ml-${game.bet._id}`,
                                              ) >= 0 && tab === params.pool
                                                ? 'action-td active'
                                                : 'action-td'
                                            }
                                            onClick={() =>
                                              onSelectBet(
                                                game.awayTeam.name,
                                                TeamTypes.AWAY,
                                                BetTypes.ML,
                                                game.bet,
                                                `ml-${game.bet._id}`,
                                                game._id,
                                                game.sporttype.value,
                                              )
                                            }
                                          >
                                            <span className="bet-num">
                                              {game.bet &&
                                                (game.bet.awayMoneyLine < 0
                                                  ? ''
                                                  : '+') +
                                                  game.bet.awayMoneyLine}
                                            </span>
                                          </div>
                                        ) : (
                                          <p className="text-secondary">
                                            {gamesTxt.noBetsAvailable}
                                          </p>
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div className="game-name text-center">
                                          <span>{game.homeTeam.name}</span>
                                          <br />
                                          <span className="text-muted small">
                                            {gamesTxt.homeTeam}
                                          </span>
                                        </div>
                                      </td>
                                      <td className="text-center">
                                        {game.bet ? (
                                          <a
                                            href="javascript:;"
                                            onClick={() =>
                                              onSelectBet(
                                                game.homeTeam.name,
                                                TeamTypes.HOME,
                                                BetTypes.PS,
                                                game.bet,
                                                `ps-${game.bet._id}`,
                                                game._id,
                                                game.sporttype.value,
                                              )
                                            }
                                          >
                                            <div
                                              className={
                                                homeTeamSelected.indexOf(
                                                  `ps-${game.bet._id}`,
                                                ) >= 0 && tab === params.pool
                                                  ? 'action-td active'
                                                  : 'action-td'
                                              }
                                            >
                                              <span className="bet-num">
                                                {game.bet &&
                                                  (game.bet.homePointSpread < 0
                                                    ? ''
                                                    : '+') +
                                                  game.bet.homePointSpread}
                                              </span>
                                              <span className="bet-num outcome-num badge badge-success">
                                                {game.bet &&
                                                  (game.bet
                                                    .homePointSpreadPayout < 0
                                                    ? ''
                                                    : '+') +
                                                  game.bet
                                                    .homePointSpreadPayout}
                                              </span>
                                            </div>
                                          </a>
                                        ) : (
                                            <p className="text-secondary">
                                              {gamesTxt.noBetsAvailable}
                                            </p>
                                          )}
                                      </td>
                                      <td className="text-center">
                                        {game.bet ? (
                                          <a
                                            href="javascript:;"
                                            data-toggle="modal"
                                          >
                                            <div
                                              className={
                                                homeTeamSelected.indexOf(
                                                  `tp-${game.bet._id}`,
                                                ) >= 0 && tab === params.pool
                                                  ? 'action-td active'
                                                  : 'action-td'
                                              }
                                              onClick={() =>
                                                onSelectBet(
                                                  game.homeTeam.name,
                                                  TeamTypes.HOME,
                                                  BetTypes.TP,
                                                  game.bet,
                                                  `tp-${game.bet._id}`,
                                                  game._id,
                                                  game.sporttype.value,
                                                )
                                              }
                                            >
                                              <span className="bet-num">
                                                {gamesTxt.ovText}{' '}
                                                {game.bet && game.bet.overUnder}
                                              </span>
                                              <span className="bet-num outcome-num badge badge-success">
                                                {game.bet &&
                                                  (game.bet.overPayout < 0
                                                    ? ''
                                                    : '+') +
                                                  game.bet.overPayout}
                                              </span>
                                            </div>
                                          </a>
                                        ) : (
                                            <p className="text-secondary">
                                              {gamesTxt.noBetsAvailable}
                                            </p>
                                          )}
                                      </td>
                                      <td className="text-center">
                                        {game.bet ? (
                                          <div
                                            className={
                                              homeTeamSelected.indexOf(
                                                `ml-${game.bet._id}`,
                                              ) >= 0 && tab === params.pool
                                                ? 'action-td active'
                                                : 'action-td'
                                            }
                                            onClick={() =>
                                              onSelectBet(
                                                game.homeTeam.name,
                                                TeamTypes.HOME,
                                                BetTypes.ML,
                                                game.bet,
                                                `ml-${game.bet._id}`,
                                                game._id,
                                                game.sporttype.value,
                                              )
                                            }
                                          >
                                            <span className="bet-num">
                                              {game.bet &&
                                                (game.bet.homeMoneyLine < 0
                                                  ? ''
                                                  : '+') +
                                                game.bet.homeMoneyLine}
                                            </span>
                                          </div>
                                        ) : (
                                            <p className="text-secondary">
                                              {gamesTxt.noBetsAvailable}
                                            </p>
                                          )}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            );
                          })
                        ) : games !== null ? (
                          <div className="d-flex align-items-center py-5 pl-2 no-pool-game">
                            <h6 className="text-white">
                              {gamesTxt.noPoolGame}
                            </h6>
                          </div>
                        ) : (
                          <div className="d-flex align-items-center py-5 pl-2 no-pool-game">
                            <h6>{appTxt.loading}</h6>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="game-bet-slip" className="game-bet-slip">
                <div
                  id="winners-list-group"
                  className="list-group list-group-flush border-radius-10 winners-list-group bg-black"
                >
                  <div
                    id="leading-scorer-header"
                    className="leading-scorer-header d-flex align-items-center justify-content-start"
                    onClick={
                      window.innerWidth <= 992
                        ? toggleLeadingScorerBox
                        : () => {}
                    }
                  >
                    <h3
                      dangerouslySetInnerHTML={{
                        __html: LanguageTexts.games.leadingScorerHeading.replace(
                          '{pool_name}',
                          activePool?.name || '',
                        ),
                      }}
                      className="winner-title mb-0"
                    />
                    {window.innerWidth <= 992 ? 
                    <span
                      id="leading-scorer-toggle-icon"
                      className="fas fa-chevron-up ml-auto leading-scorer-toggle-icon"
                    /> : null}
                  </div>
                  <div
                    id="winners-list"
                    className={`winners-list ${
                      window.innerWidth <= 992
                        ? 'winners-list-small-screen'
                        : ''
                    }`}
                  >
                    {activePoolUsers && activePoolUsers.length > 0 ? (
                      <PoolUsersList users={activePoolUsers} />
                    ) : (
                      <p style={{ textAlign: 'center' }}>
                        {appTxt.noRecordsFound}
                      </p>
                    )}
                  </div>
                </div>
                {bets && bets.length > 0 && tab === params.pool ? (
                  <div
                    id="betslip-box"
                    className={
                      window.innerWidth <= 992
                        ? `betslip-box opacity-10 stickey-box-small-screen`
                        : `betslip-box opacity-10`
                    }
                  >
                    <div id="bet-slip-header" className="bet-slip-header">
                      <div
                        id="bet-slip-header-sm"
                        onClick={
                          window.innerWidth <= 992 ? toggleBetSlipBox : () => {}
                        }
                      >
                        <h3 className="winner-title mb-0">
                          {gamesTxt.betSlipHeading}
                          <span className="ml-2">({bets.length})</span>
                          {window.innerWidth <= 992 ?
                            <span
                              id="toggle-icon"
                              className="fas fa-chevron-up ml-auto"
                            /> : null}
                        </h3>
                      </div>
                    </div>
                    <div
                      id="betslip-box-items"
                      className={
                        window.innerWidth <= 992
                          ? `betslip-box-items-small-screen`
                          : ``
                      }
                    >
                      <div
                        className="list-group flex-direction-row justify-content-center bet-tab-list mt-4"
                        id="list-tab"
                        role="tablist"
                      >
                        <a
                          className={
                            betTab === 'Straight'
                              ? 'list-group-item active list-group-item-action w-auto'
                              : 'list-group-item list-group-item-action w-auto'
                          }
                          href="javascript:void(0);"
                          onClick={() => changeBetTab('Straight')}
                          aria-controls="home"
                        >
                          {gamesTxt.tabStraightBet}
                        </a>
                        <a
                          className={
                            betTab === 'Parlay'
                              ? 'list-group-item active list-group-item-action w-auto'
                              : 'list-group-item list-group-item-action w-auto'
                          }
                          href="javascript:void(0)"
                          onClick={() => changeBetTab('Parlay')}
                          aria-controls="profile"
                        >
                          {gamesTxt.tabParlayBet}
                        </a>
                      </div>
                      <div className="row mx-3 my-4">
                        <div className="col-6">
                          {user ? (
                            <button
                              className="btn btn-primary btn-sm save-bet-btn"
                              onClick={save}
                              type="button"
                              disabled={betLoading}
                            >
                              {betLoading
                                ? appTxt.loading
                                : gamesTxt.saveBtnText}
                            </button>
                          ) : (
                            <a
                              className="btn btn-primary btn-sm save-bet-btn"
                              href="#login-modal"
                              data-toggle="modal"
                            >
                              {loginTxt.loginText}
                            </a>
                          )}
                        </div>
                        <div className="col-6 text-right">
                          <button
                            className="btn btn-primary btn-sm save-bet-btn"
                            onClick={clearBet}
                            type="button"
                            disabled={betLoading}
                          >
                            {gamesTxt.clearBetSlipBtnText}
                          </button>
                        </div>
                      </div>
                      <p className="text-white text-lg-center">{error || ''}</p>
                      <div className="betslip-items">
                        <div className="betslip-items-accordion">
                          <div className="col-12">
                            <div className="tab-content" id="nav-tabContent">
                              <div
                                className={
                                  betTab === 'Straight'
                                    ? 'tab-pane fade show active text-white'
                                    : 'tab-pane fade show text-white'
                                }
                                id="straight-bet"
                                role="tabpanel"
                                aria-labelledby="list-home-list"
                              >
                                {bets &&
                                  bets.length > 0 &&
                                  bets.map((item, index) => {
                                    return (
                                      <div
                                        key={`bet-${item.teamType}-${item.betId}`}
                                        className="betsbets mb-2"
                                      >
                                        <div className="sub-head-bet py-2">
                                          <p>
                                            {item.team}{' '}
                                            {item.type === BetTypes.PS
                                              ? item.teamType === TeamTypes.HOME
                                                ? (item.payload
                                                    .homePointSpread < 0
                                                    ? ''
                                                    : '+') +
                                                  item.payload.homePointSpread
                                                : (item.payload
                                                    .awayPointSpread < 0
                                                    ? ''
                                                    : '+') +
                                                  item.payload.awayPointSpread
                                              : item.type === BetTypes.TP
                                              ? item.teamType === TeamTypes.HOME
                                                ? `OV: ${item.payload.overUnder}`
                                                : `UN: ${item.payload.overUnder}`
                                              : null}
                                          </p>
                                          <p>
                                            {(getOddsFromType(
                                              item.type,
                                              item.payload,
                                              item.teamType,
                                            ) < 0
                                              ? ''
                                              : '+') +
                                              getOddsFromType(
                                                item.type,
                                                item.payload,
                                                item.teamType,
                                              )}
                                          </p>
                                        </div>
                                        <div className="sub-head-bet py-2">
                                          <p>{item.type}</p>
                                          <a
                                            href="javascript:void(0)"
                                            className="close-bet"
                                            onClick={() =>
                                              remove(
                                                index,
                                                item.teamType,
                                                item.betId,
                                              )
                                            }
                                          >
                                            <span className="fas fa-times-circle" />
                                          </a>
                                        </div>
                                        <div className="sub-head-bet py-2">
                                          <div className="form-group w-100 bet-amt-box">
                                            <input
                                              type="number"
                                              defaultValue={item.amount}
                                              value={item.amount}
                                              id={`amount-${item.teamType}-${item.betId}`}
                                              onChange={(event) =>
                                                onStraightBetAmountChange(
                                                  index,
                                                  event.target.value,
                                                )
                                              }
                                              className={
                                                item.isError
                                                  ? 'form-control bet-amount-value red-border'
                                                  : 'form-control bet-amount-value'
                                              }
                                            />
                                            {item.isError ? (
                                              <span className="my-4 text-danger">
                                                {
                                                  gamesTxt.betInvalidValueMessage
                                                }
                                              </span>
                                            ) : null}
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                              </div>
                              <div
                                className={
                                  betTab === 'Parlay'
                                    ? 'tab-pane fade show active text-white'
                                    : 'tab-pane fade show text-white'
                                }
                                id="parlay-bet"
                                role="tabpanel"
                                aria-labelledby="list-home-list"
                              >
                                <div className="form-group bet-amt-box">
                                  <input
                                    type="text"
                                    name="parlayAmount"
                                    defaultValue={parlayAmount}
                                    value={parlayAmount}
                                    onChange={(event) =>
                                      onParleyAmountChange(event.target.value)
                                    }
                                    className={
                                      error
                                        ? 'form-control bet-amount-value red-border'
                                        : 'form-control bet-amount-value'
                                    }
                                  />
                                  {error ? (
                                    <span className="my-4 text-danger">
                                      {gamesTxt.betInvalidValueMessage}
                                    </span>
                                  ) : null}
                                </div>
                                {bets &&
                                  bets.length > 0 &&
                                  bets.map((item, index) => {
                                    return (
                                      <div
                                        key={`bet-${item.teamType}-${item.betId}`}
                                        className="betsbets"
                                      >
                                        <div className="sub-head-bet py-2">
                                          <p>
                                            {item.team}{' '}
                                            {item.type === BetTypes.PS
                                              ? item.teamType === TeamTypes.HOME
                                                ? (item.payload
                                                    .homePointSpread < 0
                                                    ? ''
                                                    : '+') +
                                                  item.payload.homePointSpread
                                                : (item.payload
                                                    .awayPointSpread < 0
                                                    ? ''
                                                    : '+') +
                                                  item.payload.awayPointSpread
                                              : item.type === BetTypes.TP
                                              ? item.teamType === TeamTypes.HOME
                                                ? `OV: ${item.payload.overUnder}`
                                                : `UN: ${item.payload.overUnder}`
                                              : null}
                                          </p>
                                          <p>
                                            {(getOddsFromType(
                                              item.type,
                                              item.payload,
                                              item.teamType,
                                            ) < 0
                                              ? ''
                                              : '+') +
                                              getOddsFromType(
                                                item.type,
                                                item.payload,
                                                item.teamType,
                                              )}
                                          </p>
                                        </div>
                                        <div className="sub-head-bet py-2">
                                          <p>{item.type}</p>
                                          <a
                                            href="javascript:void(0)"
                                            className="close-bet"
                                            onClick={() =>
                                              remove(
                                                index,
                                                item.teamType,
                                                item.betId,
                                              )
                                            }
                                          >
                                            <span className="fas fa-times-circle" />
                                          </a>
                                        </div>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="betslip-total">
                          <div className="total-wager">
                            <span>{gamesTxt.totalWagerText}</span>
                            <span>
                              {betTab === 'Parlay'
                                ? Number(parleyPoints.points.toFixed(0)).toLocaleString().split(/\s/).join(',')
                                : Number(straightPoints.points.toFixed(0)).toLocaleString().split(/\s/).join(',')}
                            </span>
                          </div>
                          <div className="total-odds">
                            <span>{gamesTxt.oddsText}</span>
                            <span>
                              {betTab === 'Parlay'
                                ? Number(parleyPoints.oddPoints.toFixed(0)).toLocaleString().split(/\s/).join(',')
                                : Number(straightPoints.oddPoints.toFixed(0)).toLocaleString().split(/\s/).join(',')}
                            </span>
                          </div>
                          <div className="total-payout">
                            <div>
                              <span className="text-white">
                                {gamesTxt.pointText}
                              </span>
                              <br />
                              <span>{gamesTxt.potentialPayoutText}</span>
                            </div>
                            <span>
                              {betTab === 'Parlay'
                                ? Number(parleyPoints.totalBetPoints.toFixed(0)).toLocaleString().split(/\s/).join(',')
                                : Number(straightPoints.totalBetPoints.toFixed(0)).toLocaleString().split(/\s/).join(',')}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </section>
        </div>
        <div
          id="payment-modal"
          className="modal fade login-form-modal"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="modal-title font-weight-400">
                  {appTxt.join}{' '}
                  <span className="font-weight-500">{activePool?.name}</span>
                </h3>
                <button
                  type="button"
                  className="close payment-modal-close font-weight-400"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true" className="text-10">
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body p-4">
                <Formik
                  initialValues={{
                    paymentOptionId: '',
                    cvv: '',
                  }}
                  validate={validatePayment}
                  onSubmit={pay}
                >
                  {({ errors, touched }) => (
                    <Form>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <label className="text-muted font-weight-500 mr-2">
                              {gamesTxt.poolAmountText}
                            </label>
                            <span className="font-weight-500">
                              {appTxt.currencySymbol}
                              {activePool?.entry}
                            </span>
                          </div>
                        </div>
                      </div>
                      {paymentModeWallet ? (
                        <div className="row">
                          <div className="col-12">
                            <div className="form-group">
                              <label className="text-muted font-weight-500 mr-2">
                                {gamesTxt.payUsingWalletText}
                              </label>
                              <span className="font-weight-500">
                                ({appTxt.currencySymbol}
                                {user?.wallet})
                              </span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="row">
                            <div className="col-12">
                              <div className="form-group">
                                <label
                                  htmlFor="countryId"
                                  className="text-muted font-weight-500"
                                >
                                  {accountTxt.selectCardLabel}
                                </label>
                                <Select
                                  value={selectedPaymentOption}
                                  options={cards}
                                  onChange={onPaymentOptionChange}
                                />
                                {errors.paymentOptionId &&
                                  touched.paymentOptionId && (
                                    <div className="invalid-feedback-msg">
                                      {errors.paymentOptionId}
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <div className="form-group">
                                <label
                                  htmlFor="countryId"
                                  className="text-muted font-weight-500"
                                >
                                  {accountTxt.CVVLabel}
                                </label>
                                <Field
                                  name="cvv"
                                  type="text"
                                  className="form-control"
                                />
                                {errors.cvv && touched.cvv && (
                                  <div className="invalid-feedback-msg">
                                    {errors.cvv}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      <div className="form-group m-0">
                        <button
                          type="submit"
                          className="btn btn-primary btn-block"
                          disabled={paymentLoading}
                        >
                          {paymentLoading
                            ? appTxt.loading
                            : gamesTxt.payBtnText}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default GamePage;
