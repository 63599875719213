import React from 'react';
import { Route } from 'react-router-dom';

import LayoutUser from './layout/UserLayout';

type PublicRouteProps = {
  children: JSX.Element;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
};

const PublicRoute: React.FC<PublicRouteProps> = ({
  children,
  ...rest
}: PublicRouteProps): JSX.Element => {
  return (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      render={({ location }) => <LayoutUser>{children}</LayoutUser>}
    />
  );
};

export default PublicRoute;
