/* eslint-disable no-nested-ternary */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import LangaugeTexts from '../../common/language';
import { RootState } from '../../common/types';
import { logout } from '../../features/login/login.slice';

const TopHeader: React.FC = (): JSX.Element => {
  const { topHeader: topHeaderTxt, app: appTxt } = LangaugeTexts;
  const dispatch = useDispatch();

  const { user } = useSelector((state: RootState) => state.login);

  function doLogout() {
    dispatch(logout());
  }

  const windowScrollTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div id="topbar" className="d-flex align-items-center bg-white">
      <div className="container-fluid d-flex align-items-center">
        <div className="contact-info mr-auto">
          <Link to="/" onClick={windowScrollTop}>
            <img
              src={`${process.env.REACT_APP_IMAGE_PATH}website/logo.png`}
              alt=""
              width="200px"
              height="auto"
            />
          </Link>
        </div>
        {user ? (
          <div>
            <div>
              <ul className="c-header-nav ml-auto mr-0">
                <li className="mr-3">
                  <div className="d-flex text-center">
                    <div>
                      <h3 className="primary-color font-weight-500 mb-1">
                        {appTxt.currencySymbol}
                        {Number(user.wallet.toFixed(0)).toLocaleString().split(/\s/).join(',')}
                      </h3>
                      <p className="mb-2 text-muted opacity-8">
                        {topHeaderTxt.currentBalanceText}
                      </p>
                    </div>
                  </div>
                </li>
                <li className="c-header-nav-item dropdown ml-2 mr-3">
                  <Link
                    className="c-header-nav-link"
                    data-toggle="dropdown"
                    to="/"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <div className="c-avatar">
                      <img
                        className="c-avatar-img"
                        src={
                          user?.profilePicUrl !== null
                            ? user?.profilePicUrl
                            : `${process.env.REACT_APP_IMAGE_PATH}website/user.png`
                        }
                        width="50px"
                        height="50px"
                        alt={user.firstName}
                      />
                    </div>
                  </Link>
                  <div className="dropdown-menu dropdown-menu-right pt-0">
                    <div className="dropdown-header bg-light py-2">
                      <strong>{topHeaderTxt.dropdownHeader1}</strong>
                    </div>
                    <Link
                      className="dropdown-item"
                      to="/deposit"
                      onClick={windowScrollTop}
                    >
                      <span>{topHeaderTxt.dropdownDepositMenu}</span>
                    </Link>
                    <Link
                      className="dropdown-item"
                      to="/withdraw"
                      onClick={windowScrollTop}
                    >
                      <span>{topHeaderTxt.dropdownWithdrawMenu}</span>
                    </Link>
                    <div className="dropdown-header bg-light py-2">
                      <strong>{topHeaderTxt.dropdownHeader2}</strong>
                    </div>
                    <Link
                      className="dropdown-item"
                      to="/account"
                      onClick={windowScrollTop}
                    >
                      {topHeaderTxt.dropdownMyAccountMenu}
                    </Link>
                    <Link
                      className="dropdown-item"
                      to="/profile"
                      onClick={windowScrollTop}
                    >
                      {topHeaderTxt.dropdownProfileMenu}
                    </Link>
                    <Link
                      className="dropdown-item"
                      to="/add-cards"
                      onClick={windowScrollTop}
                    >
                      {topHeaderTxt.dropdownCardMenu}
                    </Link>
                    <div className="dropdown-divider" />
                    <Link className="dropdown-item" to="/" onClick={doLogout}>
                      {topHeaderTxt.dropdownLogoutMenu}
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        ) : (
          <>
            <div className="login-signup-btn mr-4">
              <Link
                to="/login"
                onClick={windowScrollTop}
                className="btn btn-primary px-4"
              >
                {topHeaderTxt.loginBtnText}
              </Link>
            </div>
            <div className="login-signup-btn">
              <Link
                to="/register"
                onClick={windowScrollTop}
                className="btn btn-dark px-4"
              >
                {topHeaderTxt.signupBtnText}
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TopHeader;
