import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import { Switch } from 'react-router-dom';

import { AppRoutePath, PublicRoutePath } from '../common/constants';
import PrivateRoute from '../components/PrivateRoute';
import PublicRoute from '../components/PublicRoute';
import ForgotPasswordPage from '../features/forgot-password/ForgotPasswordPage';
import GamePage from '../features/games/GamePage';
import HomePage from '../features/home/HomePage';
import LoginPage from '../features/login/LoginPage';
import AccountDetailsPage from '../features/profile/account-details/AccountDetailsPage';
import CreditCardPage from '../features/profile/account-details/add-cards/CreditCardPage';
import DepositMoneyPage from '../features/profile/account-details/deposit-money/DepositMoneyPage';
import WithdrawMoneyPage from '../features/profile/account-details/withdraw-money/WithdrawMoneyPage';
import ProfilePage from '../features/profile/ProfilePage';
import RegisterPage from '../features/register/RegisterPage';
import RegisterSuccessMessagePage from '../features/register/RegisterSuccessMessagePage';
import EmailVerifySuccessMessagePage from '../features/login/EmailVerifySuccessMessagePage';
import history from './history';

function RootRoutes(): JSX.Element {
  return (
    <ConnectedRouter history={history}>
      <Switch>
        <PublicRoute path={PublicRoutePath.Login}>
          <LoginPage />
        </PublicRoute>
        <PublicRoute path={PublicRoutePath.ForgotPass}>
          <ForgotPasswordPage />
        </PublicRoute>
        <PublicRoute path={PublicRoutePath.Register}>
          <RegisterPage />
        </PublicRoute>
        <PublicRoute path={PublicRoutePath.RegisterSuccess}>
          <RegisterSuccessMessagePage />
        </PublicRoute>
        <PublicRoute path={PublicRoutePath.EmailVerifySuccess}>
          <EmailVerifySuccessMessagePage />
        </PublicRoute>
        <PublicRoute path={AppRoutePath.Game}>
          <GamePage />
        </PublicRoute>
        <PrivateRoute path={AppRoutePath.Profile}>
          <ProfilePage />
        </PrivateRoute>
        <PrivateRoute path={AppRoutePath.Account}>
          <AccountDetailsPage />
        </PrivateRoute>
        <PrivateRoute path={AppRoutePath.Deposit}>
          <DepositMoneyPage />
        </PrivateRoute>
        <PrivateRoute path={AppRoutePath.Withdraw}>
          <WithdrawMoneyPage />
        </PrivateRoute>
        <PrivateRoute path={AppRoutePath.AddCards}>
          <CreditCardPage />
        </PrivateRoute>
        <PublicRoute path="/">
          <HomePage />
        </PublicRoute>
      </Switch>
    </ConnectedRouter>
  );
}

export default RootRoutes;
